<template>
    <div class="guidelines" >
        <div class="topBox">
            <p class="guidelinesTitle">指导语</p>
        </div>
        <!-- 指导语 -->
        <div class="region">
            <div class="rg">
                <div v-if="this.planInfo.isMerge === 1 || this.planInfo.isMerge === '1'">
                    <template v-for="item in info.guidelinesList" >
                <div class="text" v-if="item" :key="item.id">
                    <p>{{ item }}</p>
                </div></template>
                </div>
                <div v-else>
                    <div class="text">
                    <p>{{ info.guidelines }}</p>
                </div>
                </div>
                <button class="sure" @click="todo">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
import {getGauge} from '@/api/evaluation'
export default {
    data() {
        return {
            info: {
                guidelinesList: [],
                guidelines: ''
            },
            planInfo: null,
            userId: null
        }
    },
    methods: {
        todo() {
            // this.$router.push('/home/examination')
            this.$router.push({
                path: '/examination'
            })
            // let examinationInfo = {
            //     gaugeId: this.info.id,
            //     planId: this.info.planId,
            //     type: this.info.type,
            //     name: this.info.name
            // };
            // localStorage.setItem('examinationInfo', JSON.stringify(examinationInfo));
        },
        async getList(val) {
            const res = await getGauge({isMerge: val.isMerge, planId: val.id, isFirst: val.isFirst, id: this.userId });
            console.log(res);
            if (res.code === 200) {
                this.info.guidelinesList = res.data.guidelinesList;
            }
        }
    },
    created() {
        console.log(this.$route.meta)
        this.planInfo = JSON.parse(localStorage.getItem('planInfo'));
        // this.info = JSON.parse(localStorage.getItem('GaugeInfo'));
        console.log(this.planInfo);
        if (localStorage.getItem('patientId') !== 'null' && localStorage.getItem('patientId')) {
            this.userId = localStorage.getItem('patientId')
        } else {
            this.userId = JSON.parse(localStorage.getItem('userInfo')).id;
        }
        if(this.planInfo.isMerge === 1) {
            this.getList(this.planInfo);
            // this.$route.meta.title = this.planInfo.name;
            // this.$forceUpdate();
        } else {
            this.info.guidelines = JSON.parse(localStorage.getItem('GaugeInfo')).guidelines;
            // this.$nextTick(()=> {
            //    this.$route.meta.title = JSON.parse(localStorage.getItem('GaugeInfo')).name; 
            // })
            
            // this.$forceUpdate();
        }
    }
}
</script>

<style lang="scss" scoped>
.guidelines {
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    height: 100%;
    // height: 100% !important;
    // display: flex;
    // justify-content: center;
    .topBox{
        // height: 100px;
        background: var(--banner-color);
        box-sizing: border-box;
        display: flex;
            justify-content: center;
            align-items: center;
        .guidelinesTitle {
            width: 80%;
            font-size: 18px;
            font-weight: 600;
            color: #303030;
            margin-top: 0px;
            // padding-top: 15px;
            // text-align: center;
            
            
        }
    }
    .region {
        .rg {
            width: 80%;
            margin: auto;
        }

        p {
            margin: 0;
            padding: 0;

        }

        

        .text {
            border: 1px solid rgba(221, 221, 221, 1);
            padding: 10px;
            margin-top: 20px;

            p {
                font-size: 16px;
                font-weight: 400;
                color: #7D7D7D;
            }
        }

        .sure {
            margin-top: 40px;
            width: 100%;
            font-size: 16px;
            // width: 650px;
            height: 40px;
            background: var(--custom-color);
            border-radius: 4px;
            border: none;
            color: white;
        }
    }
}

@media screen and (min-width: 751PX) {
    .topBox{
        // background: var(--theme-color);
        height: 200px;
    }
    .region {
        width: 900px;
        // height: 100%;
        background: white;

        // .rg {
        //     // height: calc(100vh - 80px);
        // }
    }
}

@media screen and (max-width: 750PX) {
     .topBox{
        // background: var(--theme-color);
        height: 150px;
    }
    .region {
        width: 100%;
        // height: 100vh;
    }
}
</style>