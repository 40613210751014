// 测评计划
import service from '@/utils/request'
// 获取测评计划列表
export function getEvaluationList(id,params) {
  return service({
    url: `/plan/user/list/${id}`,
    method: 'get',
    params
  });
}
// 根据计划id获取量表列表
export function getGauge(params) {
  return service({
    url: '/plan/gauge/user/list',
    method: 'get',
    params
  });
}
// 根据量表id获取考试题目列表
export function getProblem(params) {
  return service({
    url: '/plan/problem/user/list',
    method: 'get',
    params
  });
}
// 提交做题结果
export function submitProblem(data) {
  return service({
    url: '/plan/save/result',
    method: 'post',
    data
  });
}
//  单个题提交
export function submitProblemOne(data) {
  return service({
    url: '/plan/save/result',
    method: 'post',
    data
  });
}
// 用户点击提交按钮
export function submitAll(data) {
  return service({
    url: '/plan/result/submit',
    method: 'post',
    data
  });
}
// 查找
export function getUserId(name, telephone, params) {
  return service({
    url: `/user/${name}/${telephone}`,
    method: 'get',
    params
  });
}